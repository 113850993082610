<template>
  <div>
    <div
      style="height: calc(100vh - 145px); overflow-y: auto; overflow-x: hidden"
    >
      <Delivery v-if="isActiveTab('tab-delivery')" />
      <Contract v-if="isActiveTab('tab-contract')" />
      <Customer v-if="isActiveTab('tab-customer')" />
      <RunSql v-if="isActiveTab('tab-riro')" />
    </div>
    <v-divider class="mt-2"></v-divider>
    <div
      class="pt-2 pb-4 pb-md-2 white-space-nowrap overflow-x-auto overflow-y-hidden"
    >
      <v-btn
        :color="isActiveTab('tab-delivery') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('tab-delivery')"
        >{{ $t("labels.delivery_company_1") }}</v-btn
      >
      <v-btn
        :color="isActiveTab('tab-contract') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('tab-contract')"
        >{{ $t("labels.delivery_contract") }}</v-btn
      >
      <v-btn
        :color="isActiveTab('tab-customer') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('tab-customer')"
        >{{ $t("labels.customer_delivery_cost") }}</v-btn
      >
      <v-btn
        v-if="isRiRo"
        :color="isActiveTab('tab-riro') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('tab-riro')"
        >RiRo</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  components: {
    Contract: () => import("@/components/delivery/Contract.vue"),
    Delivery: () => import("@/components/delivery/Delivery.vue"),
    Customer: () => import("@/components/delivery/Customer.vue"),
    RunSql: () => import("@/components/warehouse/RunSql.vue"),
  },
  data: () => ({
    isLoading: false,
    tab: "tab-delivery",
    items: [],
    isRiRo: false,
  }),
  computed: {},
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
    isActiveTab(tab) {
      return this.tab === tab;
    },
  },
  mounted() {
    const riro = this.getUrlParameter("riro");
    this.isRiRo = !!riro;
    if (this.isRiRo) {
      this.tab = "tab-riro";
    }
  },
};
</script>

<style scoped></style>
